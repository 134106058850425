<script>
import { mapGetters } from 'vuex';

export default {
	name: 'PerfilComentarios',
	mounted() {
		let { id } = this.$store.getters.userLoggedIn
		this.$store.getters.fetchGet({ path: `Comentario/ListComentario/${id}` })
			.then(response => response.json())
			.then(comentarios => this.comentarios = comentarios)
			.catch(error => console.log(error))

	},
	data() {
		return {
			msg: 'Welcome to Your PerfilComentarios App',
			comentarios: null,
			comentarioSelected: null,
			isModalActive: false,
		}
	},
	methods: {
		clickHandle(comentario) {
			this.comentarioSelected = comentario
			this.isModalActive = true
		}
	},
	computed: {
		...mapGetters(['formatDateTime'])
	},

}
</script>

<template>
	<b-container fluid>
		<b-row class="justify-content-lg-center">
			<b-col>
				<card class="pb-4 px-4">

					<template v-slot:headerTitle>
						<h4 class="card-title"><b>Comentarios evaluación</b></h4>
					</template>

					<card>
						<b-row class="py-3">
							<b-col class="h5 text-center">Ciclo de evaluación</b-col>
							<b-col class="h5 text-center">Fecha</b-col>
							<b-col class="h5 text-center">Mostrar más</b-col>
						</b-row>
						<b-row v-for="comentario in comentarios" :key="comentario.id"
							class="py-3 border-top border border-2">
							<b-col class="text-center d-flex justify-content-center align-items-center">{{
								comentario.cicloEvaluacion.descripcion }}</b-col>
							<b-col class="text-center d-flex justify-content-center align-items-center">{{ $moment.tz(comentario.fechaCreacion, 'America/Bogota').format("lll") }}</b-col>
							<b-col class="text-center d-flex justify-content-center align-items-center"> <b-button
									variant="primary" @click="clickHandle(comentario)">Mostrar mas</b-button> </b-col>
						</b-row>
					</card>

					<b-modal v-model="isModalActive" class="border border-3">
						<template #modal-title>
							<p class="h5 text-primary my-1">
								Descripción de Comentario
							</p>
						</template>
						<b-container fluid v-if="comentarioSelected">
							<b-row>
								<p class="h5">Comentario:</p>
							</b-row>
							<b-row>
								<p v-html="comentarioSelected.comentario"></p>
							</b-row>
							<b-row>
								<p class="h5">Fecha de creación:</p>
							</b-row>
							<b-row>
								<p>{{ $moment.tz(comentarioSelected.fechaCreacion, 'America/Bogota').format("lll") }}</p>
							</b-row>
						</b-container>
						<template #modal-footer>
							<b-button @click="isModalActive = false">Cerrar</b-button>
						</template>
					</b-modal>

				</card>
			</b-col>
		</b-row>
	</b-container>
</template>